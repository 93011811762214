<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 1rem; overflow-y: hidden"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      @onChange="onChange"
    />
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { axiosInstance } from "@/common/request";
export default {
  components: {
    Editor,
    Toolbar,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  watch: {
    content: {
      handler(value) {
        this.html = value;
      },
      immediate: true,
    },
  },
  data() {
    return {
      editor: null,
      html: "",
      toolbarConfig: {},
      baseUrl: `${axiosInstance.defaults.baseURL}`,
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          // 配置上传图片
          uploadImage: {
            maxFileSize: 100 * 1024 * 1024,
            // maxFileSize: 30 * 1024 * 1024, //1g //设置大点 不然图片过大会报错
            // base64LimitSize: 1000000 * 1024, // 1g 以下插入 base64
            server: `${axiosInstance.defaults.baseURL}/file/wangeditorUpload`,
            base64LimitSize: 1 * 1024 * 1024,
            fieldName: "file",
          },
          uploadVideo: {
            server: `${axiosInstance.defaults.baseURL}/file/wangeditorUpload`,
            fieldName: "file",
          },
        },
      },
      mode: "default", // or 'simple'
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },

    onChange(editor) {
      const htmlContent = editor.getHtml().trim();
      if (htmlContent === "<p><br></p>") {
        // 只包含空行
        this.$emit("onChange", "");
      } else {
        // 包含其他内容
        this.$emit("onChange", htmlContent);
      }
    },
    refresh(){
      this.html = ''
    },
    assignment(e){
    this.html = e
    }
  },
  mounted() {},
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>

<style src="@wangeditor/editor/dist/css/style.css">
</style>
